import React, { useEffect, useRef } from 'react'
import { Box, useColorModeValue } from '@chakra-ui/react'
import { withObserver } from '../shared/utils/stores'

const Component = (): JSX.Element => {
  const gameContainerRef = useRef<HTMLDivElement>(null)
  const bg = useColorModeValue('white', 'gray.800')

  useEffect(() => {
    // Add a script to fix asset paths before loading the game
    const fixAssetPaths = document.createElement('script')
    fixAssetPaths.innerHTML = `
      // Fix asset paths by intercepting image loading
      const originalImageSrc = Object.getOwnPropertyDescriptor(HTMLImageElement.prototype, 'src');
      
      // Create a proxy for the src property
      Object.defineProperty(HTMLImageElement.prototype, 'src', {
        set(value) {
          // Check if this is a relative path that needs fixing
          if (value && typeof value === 'string' && 
              !value.startsWith('http') && 
              !value.startsWith('/') && 
              !value.startsWith('data:')) {
            // Prefix with correct path
            const fixedValue = '/capeybara-game/' + value;
            console.log('Fixed asset path:', value, '->', fixedValue);
            originalImageSrc.set.call(this, fixedValue);
          } else {
            originalImageSrc.set.call(this, value);
          }
        },
        get() {
          return originalImageSrc.get.call(this);
        }
      });
      
      // Also update any existing images
      document.querySelectorAll('img').forEach(img => {
        const src = img.getAttribute('src');
        if (src && !src.startsWith('http') && !src.startsWith('/') && !src.startsWith('data:')) {
          img.src = '/capeybara-game/' + src;
        }
      });
    `
    document.head.appendChild(fixAssetPaths)

    // Load the game script
    const script = document.createElement('script')
    script.src = '/capeybara-game/game.js'
    script.async = true
    document.body.appendChild(script)

    // Cleanup function
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script)
      }
      if (document.head.contains(fixAssetPaths)) {
        document.head.removeChild(fixAssetPaths)
      }
    }
  }, [])

  return (
    <Box
      ref={gameContainerRef}
      w="100vw"
      h="100vh"
      overflow="hidden"
      position="relative"
      bg={bg}
      className="game-wrapper"
    >
      {/* This div structure matches what the game script expects */}
      <div className="game-container">
        <canvas id="gameCanvas"></canvas>
        {/* Start Screen */}
        <div id="startScreen">
          <img src="/capeybara-game/logo.png" className="logo"/>
          <div className="start-content">
            <div className="start-leaderboard">
              <h3>Beste Capeybara&apos;s</h3>
              <ol id="startLeaderboardList"></ol>
            </div>
            <div className="start-controls">
              <button id="playButton">PLAY</button>
              <div className="controls-info">
                <h4>Controls:</h4>
                <p>Pijltjes toetsen om te bewegen</p>
                <p>Z to barrel roll (3s cooldown)</p>
                <p>Spatie om te schieten</p>
                <p>ESC om te pauzeren</p>
              </div>
            </div>
          </div>
        </div>

        {/* Game UI */}
        <div className="game-ui">
          <div id="score">Score: 0</div>
          <div id="highScore">High Score: 0</div>
          <div id="laserTimer" className="hidden">
            LASER: <span id="laserTime">3.0</span>s
            <div className="timer-bar">
              <div id="laserProgress" className="timer-progress laser-progress"></div>
            </div>
          </div>
          <div id="rollCooldown" className="hidden">
            ROLL COOLDOWN: <span id="rollTime">3.0</span>s
            <div className="timer-bar">
              <div className="timer-progress roll-progress" id="rollProgress"></div>
            </div>
          </div>
        </div>

        {/* Pause Menu */}
        <div id="pauseMenu" className="pause-menu">
          <h2>PAUSED</h2>
          <button id="resumeButton">Resume Game</button>
          <button id="pauseHomeButton">Return to Menu</button>
        </div>

        {/* Game Over Screen */}
        <div id="gameOver" className="hidden">
          <h2>GAME OVER</h2>
          <p>Je score: <span id="finalScore">0</span></p>
          <div id="highScoreForm" className="hidden">
            <p>Voer je naam in voor het leaderbord:</p>
            <input type="text" id="playerName" maxLength={15} placeholder="Naam"/>
            <button id="submitScore">Versturen</button>
          </div>
          <div className="game-over-buttons">
            <button id="restartButton">Restart</button>
            <button id="homeButton">Main Menu</button>
          </div>
          <div id="leaderboard">
            <h3>Leaderboard</h3>
            <ol id="leaderboardList"></ol>
          </div>
        </div>
      </div>

      {/* Include the game's CSS */}
      <link rel="stylesheet" href="/capeybara-game/style.css"/>
    </Box>
  )
}

export const CapeybaraGame = withObserver(Component)
